// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cSEMXaTpuiz4lqmQQEBl {\n    width: 100%;\n    overflow-x: hidden;\n}\n\n.afWWOoMr2OcIPYeydJPL {\n    padding: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/Music/Music.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".Music {\n    width: 100%;\n    overflow-x: hidden;\n}\n\n.Para {\n    padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Music": "cSEMXaTpuiz4lqmQQEBl",
	"Para": "afWWOoMr2OcIPYeydJPL"
};
export default ___CSS_LOADER_EXPORT___;
