// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jzobzubOpwiWG3TGWtDc {\n    display: flex;\n    flex-direction: column;\n}\n\n.X_wEZTVlyKrCGo69Crq8 {\n    padding: 20px;\n    text-align: left;\n    text-indent: 60px;\n    background-color: white;\n    z-index: 2;\n}\n\n.LgqsG9ETxy4O8bQYr6gu {\n    background-color: transparent;\n    background: linear-gradient(180deg, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);\n    height: 100px;\n    z-index: 2;\n}\n\n.WpEGcmmxSz1fvReQGjFr {\n    align-self: center;\n    width: 100%;\n    z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,6BAA6B;IAC7B,oFAAoF;IACpF,aAAa;IACb,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd","sourcesContent":[".Home {\n    display: flex;\n    flex-direction: column;\n}\n\n.Para {\n    padding: 20px;\n    text-align: left;\n    text-indent: 60px;\n    background-color: white;\n    z-index: 2;\n}\n\n.Fade {\n    background-color: transparent;\n    background: linear-gradient(180deg, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);\n    height: 100px;\n    z-index: 2;\n}\n\n.Img {\n    align-self: center;\n    width: 100%;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Home": "jzobzubOpwiWG3TGWtDc",
	"Para": "X_wEZTVlyKrCGo69Crq8",
	"Fade": "LgqsG9ETxy4O8bQYr6gu",
	"Img": "WpEGcmmxSz1fvReQGjFr"
};
export default ___CSS_LOADER_EXPORT___;
